@import '../../../../styles/helpers';

.reviews {
  position: relative;
  z-index: 1;
  margin: 64px auto 0;

  @include media(tablet) {
    margin-top: 120px;

    &_single {
      margin-top: 96px;
    }
  }

  @include media(desktopAll) {
    margin-top: 120px;

    &_single {
      margin-top: 96px;
    }
  }

  :global {
    .splide__slide {

      &:not(.is-active) {
        opacity: 0.3;
        pointer-events: none;
      }

    }
  }

  >div {
    height: 360px;

    @at-root .reviews_single#{&} {
      height: 526px;
    }

    @include media(desktopHuge) {
      height: 832px;
    }

    @include media(portrait) {
      height: 222px;

      @at-root .reviews_single#{&} {
        height: 360px;
      }
    }

    @include media(mobile) {
      width: 100%;
      height: auto;
      aspect-ratio: 2 / 1;

      @at-root .reviews_single#{&} {
        height: auto;
      }
    }
  }

  * {
    transition: none;
  }
}

$review-width: 728px;
$review-width-desktopHuge: 1682px;
$review-width-tablet: 450px;
$review-width-mobile: calc(100vw - 64px);

.review {
  width: $review-width;
  height: 360px;

  @include media(desktopHuge) {
    height: 832px;
    width: $review-width-desktopHuge;
  }

  @include media(portrait) {
    width: $review-width-tablet;
    height: 222px;
  }

  @include media(mobile) {
    width: $review-width-mobile;
    height: auto;
    aspect-ratio: 2 / 1;
  }

  &_single {
    width: 100% !important;
    height: 526px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  background-color: transparent;
  width: 136px;
  border-radius: 0;
  display: none;
  cursor: default;

  @include media(desktopAll) {
    display: block;
    width: 136px;
    height: 94px;
  }

  @include media(desktopHuge) {
    width: 314px;
    height: 217px;
  }

  &_prev {
    left: calc(50% - $review-width / 2);
    transform: translate(calc(-100% - 30px), -50%);

    @include media(desktopHuge) {
      left: calc(50% - $review-width-desktopHuge / 2);
    }

    img {
      transform: rotate(180deg)
    }
  }

  &_next {
    right: calc(50% - $review-width / 2);
    transform: translate(calc(100% + 30px), -50%);

    @include media(desktopHuge) {
      right: calc(50% - $review-width-desktopHuge / 2);
    }
  }
}

.paginationSlider {
  max-height: 96px;

  .paginationButton {
    @include setProperty(background, var(--secondary-700), var(--secondary-50));
    border-radius: 8px;
    padding: 20px 24px;
    text-align: center;
    height: 88px;
  
    .buttonTitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.24px;
      opacity: 0.5;
      @include setProperty(color, var(--secondary-50), var(--secondary-200));
    }
  
    .buttonContent {
      margin-top: 8px;
      font-family: "KharkivTone" sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      @include setProperty(color, var(--secondary-100), var(--secondary-500));
    }

    .activeEllipse {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      visibility: hidden
    }

    &.active {
      .activeEllipse {
        visibility: visible;
      }

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0px;
        right: 0px;
        bottom: -1px;
        background: linear-gradient(85deg, rgba(42,54,52,1) 16%, rgba(42,54,52,0.4) 93%);
        border-radius: 8px;
        z-index: -1;
      }
      margin: 1px;
      margin-top: 0;

      .buttonContent {
        color: var(--primary-500);
      }
    }
  }

  :global {
    .splide__list {
      justify-content: center;
    }

    .splide__slide {
      &:not(.is-active) {
        opacity: 1;
      }
    }
  }
}